.dropdown-menu {
    min-width: 200px; /* Změňte podle potřeby */
    background-color: #62BD18;
}
.dropdown-item:hover {
    background-color: #8ACE54;
    color: #ffffff;
}
.dropdown-item {
    color: #ffffff;
}
.nav-link{
    min-width: 200px;
}
.bg-blue{
    background: #001A7B;
}

.App-link {
    color: #61dafb;
}
#navbarDropdown1{
    color: #ffffff;
}
#navbarDropdown2{
    color: #ffffff;
}
#navbarDropdown3{
    color: #ffffff;
}
#prihlaseni{
    color: #ffffff;
}

.navbar-toggler{
    background-color: #263C8F
}

.custom-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    position: relative;
}

.bar {
    display: block;
    width: 1.45em;
    height: 0.15em;
    background-color: white; /* nebo jakoukoli jinou barvu */
    margin: 0.3em 0;
    border-radius: 25px;
}

.custom-link {
    color: white; /* Ensures text is white */
    cursor: default; /* Changes cursor to pointer on hover */
    text-decoration: none; /* Optional: Removes underline from links on hover */
    text-align: center;
}

.text-white {
    color: white !important;
}

.custom-link:hover {
    color: white; /* Keeps text color white on hover */
    text-decoration: none; /* Optional: Removes underline from links on hover */
    cursor: pointer;
}

/* Menu.css */
.dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu .dropdown-menu {
      display: none; /* Initially hide the nested dropdown */
      position: absolute;
      left: 100%;
      top: 0;
  }
  
  .dropdown-submenu:hover .dropdown-menu {
      display: block; /* Show the nested dropdown on hover */
  }
  
  /* Adjust this as needed for better visibility */
  .navbar-nav .dropdown-menu {
      position: static;
  }

.pozadi_
{
    background-image: url('../pics/obr1.jpg');
    background-color: rgba(255, 255, 255, 0.5);
    background-repeat: repeat-y;
    background-size: 100% auto;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
}
.pozadi {
    background-color: rgba(255, 255, 255, 0.85);
    z-index: -1;
    padding-top: 20px;
}

.pozadi_bile {
    background-color: rgba(255, 255, 255, 0.89);
    z-index: -1;
    padding-top: 20px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.navbar-logo-link {
    display: flex;
    align-items: center;
}

.navbar-logo {
    margin-left: 15px; /* prostor mezi logem a zbytkem menu */
}


  


